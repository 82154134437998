
export default [
  {
    name: 'Welcome',
    icon: 'mdi-view-dashboard-outline',
    path: 'unit-dashboard',
    feature: 'welcome'
  },
  {
    name: 'Dashboard',
    icon: 'mdi-view-dashboard-outline',
    path: 'unit-dashboard',
    feature: 'project-management'
  },
  {
    name: 'Work Packages',
    icon: 'mdi-briefcase-outline',
    path: 'work-package-list',
    feature: 'project-management'
  },
  {
    name: 'Publication Goals',
    path: 'publication-goals',
    icon: 'mdi-flag-checkered',
    feature: 'project-management'
  },
  {
    name: 'DFG Survey',
    icon: 'mdi-pencil-box-outline',
    path: 'dfg',
  },
  {
    name: 'Dataverse',
    icon: 'mdi-book-arrow-right',
    path: 'out-dataverse'
  },
  {
    name: 'Publications',
    icon: 'mdi-book-open-outline',
    path: 'publication-list',
    children: [
      {
        name: 'My Publications',
        path: 'publication-my-goals'
      },
      {
        name: 'Library',
        path: 'publication-list',
      },
      {
        name: 'Add Publication',
        path: 'se2a-publication-create'
      },
      {
        name: 'Publication Policy',
        path: 'publication-policy',
        feature: 'disabled'
      }
    ]
  },
  /*{
    name: 'Forms',
    path: 'form-index',
    icon: 'mdi-form-select'
  },*/
  {
    name: 'Qualification Plans',
    path: 'qualification-plan-index',
    icon: 'mdi-form-select'
  }
]
